@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Custom colors */
:root {
  --color-background: #202020;
  --color-foreground: #f5f5f5;
  --color-primary: #F46A35;
  --color-secondary: #3983FA;
  --color-border: #505050;
  --color-card: #2a2a2a;
  --color-text: #ffffff;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

.header, .footer, .chatbot, .segmentation, .features, .pricing, .start {
  background-color: var(--color-background);
  color: var(--color-text);
}

.table th, .table td {
  border-color: var(--color-border) !important;
}

.table th {
  background-color: var(--color-primary) !important;
}

.table tbody tr {
  background-color: var(--color-card);
}

.table tbody tr td, .table tbody tr th {
  color: var(--color-text);
}

